<template>
  <div class="page-main system-message">
    <template v-for="(item, i) in infoColumnData">
      <div v-if="item.children" :key="i">
        <div class="module-title" v-if="item.label">{{ item.label }}</div>
        <div
          class="cell"
          v-for="(item2, i2) in item.children"
          :key="i2"
          style="padding: 10px;"
        >
          <div class="label">{{ $t(item2.label) }}</div>
          {{ infoData[item2.porp] || '--' }}
        </div>
      </div>
      <div v-else :key="i">{{ item.label }}:{{ infoData[item.porp] || '--' }}</div>
    </template>
  </div>
</template>

<script>
import { deviceDetails } from '@/api/device'
export default {
  name: 'tab-pane-system-message',
  data() {
    return {
      infoData: {},
      infoColumnData: [
        {
          children: [
            { label: 'lastRebootTime', porp: 'rebootTime' },
            { label: 'hardwareVersion', porp: 'hardware' },
            { label: 'softwareVersion', porp: 'firmware' },
            { label: 'MACAddress', porp: 'macAddress' },
            { label: 'sn', porp: 'snCode' },
          ],
        },
        {
          label: 'IPv4',
          children: [
            { label: 'addressType', porp: 'ipv4Type' },
            { label: 'IPv4Address', porp: 'ipv4' },
            { label: 'subnetMask', porp: 'ipv4Mask' },
            { label: 'defaultGateway', porp: 'ipv4Gateway' },
            { label: 'DNSServer1', porp: 'ipv4Dns1' },
            { label: 'DNSServer2', porp: 'ipv4Dns2' },
          ],
        },
        {
          label: 'IPv6',
          children: [
            { label: 'addressType', porp: 'ipv6Type' },
            { label: 'IPv6Address', porp: 'ipv6' },
            { label: 'DNSServer1', porp: 'ipv6Dns1' },
            { label: 'DNSServer2', porp: 'ipv6Dns2' },
          ],
        },
      ],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      deviceDetails({ id: this.$route.query.id })
        .then(({ data }) => {
          this.infoData = data.result.rows[0]
          this.infoData && (this.infoData.macAddress = this.infoData.macAddress.toUpperCase())
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.system-message {
  height: 100%;
  background-color: #FFF;
  padding: 80px 140px 20px;
  color: #606266;
  .module-title {
    margin-bottom: 47px;
    font-size: 20px;
    font-weight: bold;
    line-height: 34px;
    color: $--color-primary;
  }
  .cell {
    display: flex;
    height: 54px;
    line-height: 54px;
    .label {
      width: 145px;
      padding-right: 30px;
      font-size: 16px;
      text-align: right;
    }
  }
}
</style>