<template>
  <div class="page">
    <div class="page-main bg-transparent">
      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('device.systemMessage')" name="systemMessage" lazy>
          <tab-pane-system-message></tab-pane-system-message>
        </el-tab-pane>
        <el-tab-pane :label="$t('device.accountStatus')" name="accountStatus" lazy>
          <tab-pane-account-status></tab-pane-account-status>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import TabPaneSystemMessage from './tab-pane-system-message'
import TabPaneAccountStatus from './tab-pane-account-status'

export default {
  name: 'DeviceDetailInfo',
  components: {
    TabPaneSystemMessage,
    TabPaneAccountStatus,
  },
  data() {
    return {
      activeName: 'systemMessage',
    }
  },
  methods: {},
}
</script>
