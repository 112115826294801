<template>
  <div class="account-status">
    <table-pagination
      memory="tabPaneAccountStatus"
      :tableData="tableData"
      :columnData="columnData"
      columnOptionShow
      paginationHide
    ></table-pagination>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { deviceAccoutnStatus } from '@/api/device'
import { TablePagination } from '@/components'

export default {
  name: 'tab-pane-account-status',
  components: { TablePagination },
  data() {
    return {
      columnData: [
        {
          label: this.$t('device.account'),
          prop: 'accountNo',
          minWidth: 100,
          filter: (val) => this.$t('device.account') + val,
        },
        {
          label: this.$t('sipAccount.userName'),
          prop: 'sipUserId',
          minWidth: 100,
        },
        {
          label: this.$t('sipServer.serverName'),
          prop: 'serverName',
          minWidth: 140,
        },
        {
          label: this.$t('sipAccount.sipServer'),
          prop: 'sipServer',
          minWidth: 100,
        },
        {
          label: this.$t('device.accountStatus'),
          prop: 'accountStatus',
          minWidth: 140,
          filter: (val) => this.deviceAccountStatus[val],
        },
      ],
      tableData: [],
    }
  },
  computed: {
    ...mapGetters('dist', ['deviceAccountStatus']),
  },
  created() {
    this.getTableData()
  },
  methods: {
    getTableData() {
      deviceAccoutnStatus({ id: this.$route.query.id })
        .then(({ data }) => {
          this.tableData = data.result.rows
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.account-status {
  height: 100%;
  background-color: #FFF;
}
</style>